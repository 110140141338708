'use client';

import { ComponentProps, HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'next/navigation';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { IntercomFiberPatcher } from '@/blocks/components/Intercom/IntercomFiberPatcher';
import { utilityParams } from '@/global/searchParamsRegistry';
import { useIsRtl } from '@/hooks/useIsRtl';

import styles from './styles.module.scss';

const INIT_DELAY = 3000;
const INIT_DELAY_WHEN_OPEN_IMMEDIATELY = 0;

const BOOT_POOLING_INTERVAL = 200;
const BOOT_POOLING_TIMEOUT = INIT_DELAY + 10000;

const PADDING = 46;

function useOpenChatImmediately() {
  const searchParams = useSearchParams();
  const openChatImmediately = searchParams.get(utilityParams.chat) !== null;
  return { openChatImmediately };
}

function useIntercomBootPooling() {
  const [intercomBooted, setIntercomBooted] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.intercomSettings?._isBooted) {
        setIntercomBooted(true);
        clearInterval(interval);
        clearTimeout(timeout);
        console.info('Intercom booted');
      }
    }, BOOT_POOLING_INTERVAL);

    const timeout = setTimeout(() => {
      if (!window?.intercomSettings?._isBooted) {
        console.error('Intercom was not booted within expected time frame, boot pooling stopped');
        clearInterval(interval);
      }
    }, BOOT_POOLING_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  return { intercomBooted };
}

function IntercomImmediatelyShowController(props: { children: ReactNode }) {
  const { show } = useIntercom();
  const { openChatImmediately } = useOpenChatImmediately();
  const { intercomBooted } = useIntercomBootPooling();

  useEffect(() => {
    if (intercomBooted && openChatImmediately) {
      console.info('Intercom showed immediately');
      show();
    }
  }, [intercomBooted, openChatImmediately, show]);

  return props.children;
}

export function WithIntercom(props: ComponentProps<typeof IntercomProvider>) {
  const { isRtl } = useIsRtl();
  const { openChatImmediately } = useOpenChatImmediately();

  return (
    <IntercomProvider
      autoBoot={true}
      initializeDelay={openChatImmediately ? INIT_DELAY_WHEN_OPEN_IMMEDIATELY : INIT_DELAY}
      autoBootProps={{
        horizontalPadding: PADDING,
        verticalPadding: PADDING,
        alignment: isRtl ? 'left' : 'right',
        // Detect that this function was finished https://github.com/devrnt/react-use-intercom/blob/84b7bd900d7e88a95f0eda2b6d3004b2a2bc05a9/packages/react-use-intercom/src/provider.tsx#L62
        // and set isBooted = true https://github.com/devrnt/react-use-intercom/blob/84b7bd900d7e88a95f0eda2b6d3004b2a2bc05a9/packages/react-use-intercom/src/provider.tsx#L90
        // to avoid https://github.com/devrnt/react-use-intercom/blob/84b7bd900d7e88a95f0eda2b6d3004b2a2bc05a9/packages/react-use-intercom/src/provider.tsx#L124
        // because intercomSettings are filled almost in the end of boot execution, and can be used as indicator what isBooted = true
        // read react-use-intercom source code for more information
        customAttributes: { _isBooted: true },
      }}
      {...props}
    >
      <IntercomImmediatelyShowController>
        <IntercomFiberPatcher />
        {props.children}
      </IntercomImmediatelyShowController>
    </IntercomProvider>
  );
}

/*

Dont use it for
<button ... onClick={show}>...</button>

Use
<ShowIntercomBtn>...</ShowIntercomBtn>
instead

 */
export function useIntercomWrap() {
  const params = useIntercom();
  return {
    ...params,
  };
}

export function ShowIntercomBtn({
  children,
  className,
  title,
  onClick: propsOnClick,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  const { show } = useIntercomWrap();
  const { intercomBooted } = useIntercomBootPooling();
  const [isLoading, setIsLoading] = useState(false);
  const wasShowed = useRef(false);

  useEffect(() => {
    if (intercomBooted) {
      window.Intercom('onShow', () => {
        setIsLoading(false);
        wasShowed.current = true;
      });
    }
  }, [intercomBooted]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!wasShowed.current) {
        setIsLoading(true);
      }

      show();

      if (typeof propsOnClick === 'function') {
        propsOnClick(e);
      }
    },
    [propsOnClick, show],
  );

  return (
    <button
      {...props}
      className={classNames(className, isLoading && styles.loading)}
      onClick={onClick}
      title={isLoading ? 'Loading...' : title}
    >
      {children}
    </button>
  );
}
